import React, { useState, useEffect } from 'react';
import { auth, db } from '../firebase/firebaseConfig';
import { getDocs, collection, where, query } from 'firebase/firestore';
import { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import { Card } from 'semantic-ui-react'
import { Titulo, Contenedor } from '../elementos/General';

const ServicioTecnico = () => {
    const user = auth.currentUser;
    const { users } = useContext(UserContext);

    const [asignar, setAsignar] = useState([]);

    const getIngresostcab = async () => {
        const traerCabecera = collection(db, 'ingresostcab');
        const dato = query(traerCabecera, where('emp_id', '==', users.emp_id));
        const data = await getDocs(dato)
        setAsignar(data.docs.map((doc, index) => ({ ...doc.data(), id: doc.id })))
    }
    const porconfimar = asignar.filter(mp => mp.estado === 'POR CONFIRMAR').length;
    // const asignarOrdmp = mantoprev.sort((a, b) => a.folio - b.folio);
    const ingresado = asignar.filter(p => p.estado === 'INGRESADO').length;
    // const asignarOrdp = presu.sort((a, b) => a.folio - b.folio);
    const asignado = asignar.filter(e => e.estado === 'ASIGNADO').length;
    // const asignarOrde = evaluacion.sort((a, b) => a.folio - b.folio);
    const cerradas = asignar.filter(e => e.estado === 'CERRADO').length;

    useEffect(() => {
        getIngresostcab();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Contenedor>
            <Titulo style={{marginTop: '30px'}}>DashBoard</Titulo>
            {/* <img style={{ width:"600px"}} src="../../tecnico.png" alt="imagen de servicio tecnico" /> */}
            <div style={{margin: '50px'}}>
                <Card.Group itemsPerRow={4} >
                    {/* <Card color='red' style={{ padding: '20px', fontSize: '50px' }}>
                    <h4>Total por Confirmar</h4>
                    <p>{porconfimar}</p>
                </Card> */}
                    <Card color='orange' style={{ padding: '20px', fontSize: '50px', width: '200px' }}>
                        <h4 style={{ fontSize: '16px' }}>Ordenes Ingresadas</h4>
                        <p>{ingresado}</p>
                    </Card>
                    <Card color='yellow' style={{ padding: '20px', fontSize: '50px', width: '200px' }}>
                        <h4 style={{ fontSize: '16px' }}>Ordenes Asignadas</h4>
                        <p>{asignado}</p>
                    </Card>
                    <Card color='olive' style={{ padding: '20px', fontSize: '50px', width: '200px' }}>
                        <h4 style={{ fontSize: '16px' }}>Ordenes  Cerradas </h4>
                        <p>{cerradas}</p>
                    </Card>

                </Card.Group>
            </div>
        </Contenedor>
    );
};

export default ServicioTecnico;