import React from 'react';


const Configuracion = () => {
    return (
        <div>
                <img style={{ width:"600px"}} src="../../configuracion.png" alt="imagen" />
        </div>            
    );
};

export default Configuracion;

