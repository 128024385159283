/* eslint-disable array-callback-return */
import React, { useState, useEffect, /*useRef*/ } from 'react';
import Alertas from './Alertas';
import { Table } from 'semantic-ui-react';
import { auth, db } from '../firebase/firebaseConfig';
// import { getDocs, collection, where, query, addDoc, writeBatch, doc, updateDoc } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import useObtenerIngreso from '../hooks/useObtenerIngreso';
// import * as FaIcons from 'react-icons/fa';
import { ContenedorProveedor, Contenedor, /* ContentElemenAdd, ListarProveedor, InputAdd, Boton */ Titulo, BotonGuardar, Subtitulo, Overlay, ConfirmaModal, ConfirmaBtn, Boton2 } from '../elementos/General'
// import { ListarEquipos } from '../elementos/CrearEquipos'
import { useContext } from 'react';
import { UserContext } from '../context/UserContext';
// import { TbNotes } from "react-icons/tb";
// import { TbNotesOff } from "react-icons/tb";
import moment from 'moment';
// import Swal from 'sweetalert2';

const EjecutarPresupuestoSt = () => {
  //fecha hoy
  // let fechaAdd = new Date();
  // let fechaMod = new Date();
  const user = auth.currentUser;
  const { users } = useContext(UserContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const [ingreso] = useObtenerIngreso(id);

  const [estadoAlerta, cambiarEstadoAlerta] = useState(false);
  const [alerta, cambiarAlerta] = useState({});
  // const [itemsInst, setItemsInst] = useState([]);
  // const [itemsCheck, setItemsCheck] = useState([]);
  // const [itemsMedicion, setItemsMedicion] = useState([]);
  // const [itemsSeg, setItemsSeg] = useState([]);
  const [folio, setFolio] = useState('');
  const [rut, setRut] = useState('');
  const [entidad, setEntidad] = useState('');
  const [date, setDate] = useState('');
  const [telefono, setTelefono] = useState('');
  const [direccion, setDireccion] = useState('');
  const [correo, setCorreo] = useState('');
  const [familia, setFamilia] = useState('');
  const [tipo, setTipo] = useState('');
  const [marca, setMarca] = useState('');
  const [modelo, setModelo] = useState('');
  const [serie, setSerie] = useState('');
  const [servicio, setServicio] = useState('');
  const [buscador, setBuscardor] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  // const [flag, setFlag] = useState(false);
  // const [btnGuardar, setBtnGuardar] = useState(false);
  // const [btnConfirmar, setBtnConfirmar] = useState(false);
  // const falsosCheck = useRef([]);
  // const falsosLlenado = useRef([]);
  // const falsosSelec = useRef([]);
  // const documentoId = useRef('');
  // const idbitacora = useRef('');

  const volver = () => {
    navigate('/serviciotecnico/asignadostecnicos');
  }

  useEffect(() => {
    if (ingreso) {
      setFolio(ingreso.folio);
      setRut(ingreso.rut);
      setEntidad(ingreso.entidad);
      setDate(ingreso.date);
      setTelefono(ingreso.telefono);
      setDireccion(ingreso.direccion);
      setCorreo(ingreso.correo);
      setFamilia(ingreso.familia);
      setTipo(ingreso.tipo);
      setMarca(ingreso.marca);
      setModelo(ingreso.modelo);
      setSerie(ingreso.serie);
      setServicio(ingreso.servicio);
    } else {
      navigate('/serviciotecnico/asignadostecnicos')
    }
  }, [ingreso, navigate])

  const onBuscarCambios = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setBuscardor(target.value)
  }

  // Cambiar fecha
  const formatearFecha = (fecha) => {
    const dateObj = fecha.toDate();
    const formatear = moment(dateObj).format('DD/MM/YYYY HH:mm');
    // const fechaHoyF = moment(fechaHoy).format('DD/MM/YYYY HH:mm');
    return formatear;
  }
  // const handleButtonClickLlen = (e, index) => {
  //   setItemsMedicion((prevItems) => {
  //     const nuevosElementos = [...prevItems];
  //     nuevosElementos[index].valor = e.target.value;
  //     return nuevosElementos;
  //   });
  // }

  // // Agregar Cabecera de Protocolo / Boton Siguiente
  // const addCabBitacora = async (ev) => {
  //   // ev.preventDefault();
  //   cambiarEstadoAlerta(false);
  //   cambiarAlerta({});
  //   // Filtar por docuemto de Cabecera Bitacora
  //   const cab = query(collection(db, 'bitacoracabst'), where('emp_id', '==', users.emp_id), where('folio_ingreso', '==', folio), where('confirmado', '==', false));
  //   const cabecera = await getDocs(cab);
  //   const existeCab = (cabecera.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  //   // Filtar por docuemto de Cabecera protocolo
  //   const prot = query(collection(db, 'protocoloscab'), where('emp_id', '==', users.emp_id), where('tipo', '==', tipo), where('programa', '==', 'ANUAL'));
  //   const proto = await getDocs(prot);
  //   const protocolo = (proto.docs.map((doc) => ({ ...doc.data(), id: doc.id })));

  //   if (existeCab.length > 0) {
  //     documentoId.current = existeCab[0].id
  //   } else {
  //     try {
  //       BitacoraCabStDB({
  //         nombre_protocolo: protocolo[0].nombre,
  //         cab_id_protocolo: protocolo[0].id,
  //         fecha_mantencion: fechaAdd,
  //         id_ingreso: id,
  //         folio_ingreso: folio,
  //         confirmado: false,
  //         userAdd: user.email,
  //         userMod: user.email,
  //         fechaAdd: fechaAdd,
  //         fechaMod: fechaMod,
  //         emp_id: users.emp_id,
  //       })
  //       cambiarEstadoAlerta(true);
  //       cambiarAlerta({
  //         tipo: 'exito',
  //         mensaje: 'Proceda a realizar la mantención'
  //       })
  //     } catch (error) {
  //       cambiarEstadoAlerta(true);
  //       cambiarAlerta({
  //         tipo: 'error',
  //         mensaje: error
  //       })
  //     }
  //   }
  // }
  // // Leer cabeceras de Bitacoras
  // const bitacorasCab = async () => {
  //   const doc = query(collection(db, 'bitacoracabst'), where('emp_id', '==', users.emp_id), where('id_ingreso', '==', id));
  //   const docu = await getDocs(doc);
  //   const documen = (docu.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  //   idbitacora.current = documen[0].id
  // }

  // // Leer detalles de Bitacoras
  // const consultarBitacoras = async () => {
  //   const docInst = query(collection(db, 'bitacorasst'), where('emp_id', '==', users.emp_id), where('cab_id_bitacora', '==', idbitacora.current), where('categoria', '==', 'INSTRUMENTOS'));
  //   const docuInst = await getDocs(docInst);
  //   const documenInst = (docuInst.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  //   setItemsInst(documenInst);
  //   const docCheck = query(collection(db, 'bitacorasst'), where('emp_id', '==', users.emp_id), where('cab_id_bitacora', '==', idbitacora.current), where('categoria', '==', 'CHECK'));
  //   const docuCheck = await getDocs(docCheck);
  //   const documenCheck = (docuCheck.docs.map((doc, index) => ({ ...doc.data(), id: doc.id, id2: index + 1 })));
  //   setItemsCheck(documenCheck);
  //   const docLlen = query(collection(db, 'bitacorasst'), where('emp_id', '==', users.emp_id), where('cab_id_bitacora', '==', idbitacora.current), where('categoria', '==', 'MEDICION'));
  //   const docuLlen = await getDocs(docLlen);
  //   const documenLlen = (docuLlen.docs.map((doc, index) => ({ ...doc.data(), id: doc.id, id2: index + 1 })));
  //   setItemsMedicion(documenLlen);
  //   const docSel = query(collection(db, 'bitacorasst'), where('emp_id', '==', users.emp_id), where('cab_id_bitacora', '==', idbitacora.current), where('categoria', '==', 'SEGURIDAD'));
  //   const docuSel = await getDocs(docSel);
  //   const documenSel = (docuSel.docs.map((doc, index) => ({ ...doc.data(), id: doc.id, id2: index + 1 })));
  //   setItemsSeg(documenSel);
  // }
  // // Boton Guardar
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   cambiarEstadoAlerta(false);
  //   cambiarAlerta({});
  //   const docCheck = query(collection(db, 'bitacorasst'), where('emp_id', '==', users.emp_id), where('cab_id_bitacora', '==', idbitacora.current), where('categoria', '==', 'CHECK'));
  //   const docuCheck = await getDocs(docCheck);
  //   const documenCheck = (docuCheck.docs.map((doc) => ({ ...doc.data(), id: doc.id })));

  //   if (documenCheck.length === 0) {
  //     // Crea una nueva instancia de lote (batch)
  //     const batch = writeBatch(db);
  //     // Obtiene una referencia a una colección específica en Firestore
  //     const bitacoraRef = collection(db, 'bitacorasst');
  //     // Itera a través de los nuevos documentos y agrégalos al lote de Checks
  //     itemsCheck.forEach((docs) => {
  //       const nuevoDocRef = doc(bitacoraRef); // Crea una referencia de documento vacía (Firestore asignará un ID automáticamente)
  //       batch.set(nuevoDocRef, {
  //         item: docs.item,
  //         valor: docs.valor,
  //         categoria: docs.categoria,
  //         cab_id_bitacora: documentoId.current,
  //         useradd: user.email,
  //         usermod: user.email,
  //         fechaadd: fechaAdd,
  //         fechamod: fechaMod,
  //         emp_id: users.emp_id,
  //       });
  //     });
  //     // Itera a través de los nuevos documentos y agrégalos al lote de Llenado
  //     itemsMedicion.forEach((docs) => {
  //       const nuevoDocRef = doc(bitacoraRef); // Crea una referencia de documento vacía (Firestore asignará un ID automáticamente)
  //       batch.set(nuevoDocRef, {
  //         item: docs.item,
  //         valor: docs.valor,
  //         medida: docs.medida,
  //         inicial: docs.inicial,
  //         final: docs.final,
  //         categoria: docs.categoria,
  //         cab_id_bitacora: documentoId.current,
  //         useradd: user.email,
  //         usermod: user.email,
  //         fechaadd: fechaAdd,
  //         fechamod: fechaMod,
  //         emp_id: users.emp_id,
  //       });
  //     });
  //     // Itera a través de los nuevos documentos y agrégalos al lote de Seleccion
  //     itemsSeg.forEach((docs) => {
  //       const nuevoDocRef = doc(bitacoraRef); // Crea una referencia de documento vacía (Firestore asignará un ID automáticamente)
  //       batch.set(nuevoDocRef, {
  //         item: docs.item,
  //         valor: docs.valor,
  //         categoria: docs.categoria,
  //         cab_id_bitacora: documentoId.current,
  //         useradd: user.email,
  //         usermod: user.email,
  //         fechaadd: fechaAdd,
  //         fechamod: fechaMod,
  //         emp_id: users.emp_id,
  //       });
  //     });
  //     batch.commit()
  //       .then(() => {
  //         cambiarEstadoAlerta(true);
  //         cambiarAlerta({
  //           tipo: 'exito',
  //           mensaje: 'Docuemento creado correctamente.'
  //         });
  //       })
  //       .catch((error) => {
  //         Swal.fire('Se ha producido un error al crear docuemento');
  //         console.log(error)
  //       });
  //   } else {
  //     // Crea una nueva instancia de lote (batch)
  //     const batch = writeBatch(db);
  //     // Obtiene una referencia a una colección específica en Firestore
  //     const bitacoraRef = collection(db, 'bitacorasst',);
  //     // Itera a través de los nuevos documentos y agrégalos al lote de Checks
  //     itemsCheck.forEach((docs) => {
  //       const nuevoDocRef = doc(bitacoraRef, docs.id); // Crea una referencia de documento vacía (Firestore asignará un ID automáticamente)
  //       batch.update(nuevoDocRef, {
  //         valor: docs.valor,
  //         usermod: user.email,
  //         fechamod: fechaMod,
  //       });
  //     });
  //     // Itera a través de los nuevos documentos y agrégalos al lote de Llenado
  //     itemsMedicion.forEach((docs) => {
  //       const nuevoDocRef = doc(bitacoraRef, docs.id); // Crea una referencia de documento vacía (Firestore asignará un ID automáticamente)
  //       batch.update(nuevoDocRef, {
  //         valor: docs.valor,
  //         usermod: user.email,
  //         fechamod: fechaMod,
  //       });
  //     });
  //     // Itera a través de los nuevos documentos y agrégalos al lote de Seleccion
  //     itemsSeg.forEach((docs) => {
  //       const nuevoDocRef = doc(bitacoraRef, docs.id); // Crea una referencia de documento vacía (Firestore asignará un ID automáticamente)
  //       batch.update(nuevoDocRef, {
  //         valor: docs.valor,
  //         usermod: user.email,
  //         fechamod: fechaMod,
  //       });
  //     });
  //     batch.commit()
  //       .then(() => {
  //         cambiarEstadoAlerta(true);
  //         cambiarAlerta({
  //           tipo: 'exito',
  //           mensaje: 'Docuemento actualizado correctamente.'
  //         });
  //       })
  //       .catch((error) => {
  //         Swal.fire('Se ha producido un error al actualizar docuemento');
  //         console.log(error)
  //       });
  //   }

  //   try {
  //     await updateDoc(doc(db, 'ingresostcab', id), {
  //       enproceso: '1',
  //       usermod: user.email,
  //       fechamod: fechaMod
  //     });
  //   } catch (error) {
  //     cambiarEstadoAlerta(true);
  //     cambiarAlerta({
  //       tipo: 'error',
  //       mensaje: 'Error al actualizar Ingreso:', error
  //     })
  //   }
  //   setBtnGuardar(true);
  //   setBtnConfirmar(false);
  // }

  // // Función para actualizar documentos / Boton Confirmar
  // const actualizarDocs = async () => {
  //   cambiarEstadoAlerta(false);
  //   cambiarAlerta({});
  //   // Filtar por docuemto de Cabecera Bitacora
  //   const cab = query(collection(db, 'bitacoracabst'), where('emp_id', '==', users.emp_id), where('folio_ingreso', '==', folio), where('confirmado', '==', false));
  //   const cabecera = await getDocs(cab);
  //   const existeCab = (cabecera.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  //   console.log(existeCab)

  //   itemsCheck.forEach((docs, index) => {
  //     falsosCheck.current = itemsCheck.filter(ic => ic.valor === '')
  //   });
  //   itemsMedicion.forEach((docs, index) => {
  //     falsosLlenado.current = itemsMedicion.filter(ic => ic.valor === '')
  //   });
  //   itemsSeg.forEach((docs, index) => {
  //     falsosSelec.current = itemsSeg.filter(ic => ic.valor === '')
  //   });

  //   if (falsosCheck.current.length > 0) {
  //     Swal.fire(`Item checked ${falsosCheck.current.map((i) => {
  //       return i.id2;
  //     })} no puede estar vacio. Recuerde guardar antes de Confirmar`);
  //     setBtnGuardar(false);
  //     setBtnConfirmar(true);

  //   } else if (falsosLlenado.current.length > 0) {
  //     Swal.fire(`Tabla Vacio: Item ${falsosLlenado.current.map((i) => {
  //       return i.id2;
  //     })} no puede estar vacio. Recuerde guardar antes de Confirmar`);
  //     setBtnGuardar(false);
  //     setBtnConfirmar(true);

  //   } else if (falsosSelec.current.length > 0) {
  //     Swal.fire(`Tabla Seguridad: Item ${falsosSelec.current.map((i) => {
  //       return i.id2;
  //     })} no puede estar vacio. Recuerde guardar antes de Confirmar`);
  //     setBtnGuardar(false);
  //     setBtnConfirmar(true);
  //   } else {
  //     try {
  //       await updateDoc(doc(db, 'ingresostcab', id), {
  //         enproceso: '0',
  //         estado: 'CERRADO',
  //         usermod: user.email,
  //         fechamod: fechaMod
  //       });
  //     } catch (error) {
  //       cambiarEstadoAlerta(true);
  //       cambiarAlerta({
  //         tipo: 'error',
  //         mensaje: 'Error al confirmar Mantencion:', error
  //       })
  //     }
  //     try {
  //       await updateDoc(doc(db, 'bitacoracabst', existeCab[0].id), {
  //         confirmado: true,
  //         usermod: user.email,
  //         fechamod: fechaMod
  //       });
  //     } catch (error) {
  //       cambiarEstadoAlerta(true);
  //       cambiarAlerta({
  //         tipo: 'error',
  //         mensaje: 'Error al confirmar Bitacora:', error
  //       })
  //       console.log(error)
  //     }
  //     Swal.fire('Check List de Mantención realizado con exito!').then((result) => {
  //       navigate('/serviciotecnico/asignadostecnicos')
  //     })
  //   }
  // }

  // const cancelDelete = () => {
  //   setShowConfirmation(false);
  // }

  // useEffect(() => {
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])
  // useEffect(() => {
  //   if (ingreso.enproceso === '1') {
  //     bitacorasCab();
  //     consultarBitacoras();
  //   } else {
  //     consultarProtocolos();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [flag, setFlag])

  return (
    <ContenedorProveedor style={{ width: '80%' }}>
      <Contenedor>
        <Titulo>Ejecutar Presupuesto</Titulo>
      </Contenedor>

      <Contenedor>
        {/* Informacion Cliente */}
        <Subtitulo style={{ fontSize: '18px' }}>Informacion Cliente</Subtitulo>
        <Table singleLine style={{ fontSize: '12px', lineHeight: '8px' }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Folio</Table.HeaderCell>
              <Table.HeaderCell>Rut</Table.HeaderCell>
              <Table.HeaderCell>Nombre</Table.HeaderCell>
              <Table.HeaderCell>Fecha</Table.HeaderCell>
              <Table.HeaderCell>Telefono</Table.HeaderCell>
              <Table.HeaderCell>Dirección</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>{folio}</Table.Cell>
              <Table.Cell>{rut}</Table.Cell>
              <Table.Cell>{entidad}</Table.Cell>
              <Table.Cell>{date ? formatearFecha(date) : '00/00/00 00:00'}</Table.Cell>
              <Table.Cell>{telefono}</Table.Cell>
              <Table.Cell>{direccion}</Table.Cell>
              <Table.Cell>{correo}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        {/* Informacion Equipo */}
        <Subtitulo style={{ fontSize: '18px' }}>Informacion Equipo</Subtitulo>
        <Table singleLine style={{ fontSize: '12px', lineHeight: '8px' }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Familia</Table.HeaderCell>
              <Table.HeaderCell>Tipo Equipamiento</Table.HeaderCell>
              <Table.HeaderCell>Marca</Table.HeaderCell>
              <Table.HeaderCell>Modelo</Table.HeaderCell>
              <Table.HeaderCell>Serie</Table.HeaderCell>
              <Table.HeaderCell>Servicio</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row >
              <Table.Cell>{familia}</Table.Cell>
              <Table.Cell>{tipo}</Table.Cell>
              <Table.Cell>{marca}</Table.Cell>
              <Table.Cell>{modelo}</Table.Cell>
              <Table.Cell>{serie}</Table.Cell>
              <Table.Cell>{servicio}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        {/* {
          isOpen &&
          <BotonGuardar onClick={() => {
            setMostrar(true);
            setIsOpen(false);
            setFlag(!flag);
            // addCabBitacora();
          }}>Siguente</BotonGuardar>
        } */}

      </Contenedor>
      <BotonGuardar /* disabled={btnCab} */ style={{ marginTop: '20px', /* backgroundColor: btnCab && '#8F8B85', cursor: btnCab && 'default'*/ }} /* onClick={addCabPresupuesto} */ >Comenzar Presupuesto</BotonGuardar>


      {/* Modal para de Confirar Mantencion Preventiva*/}
      {
        showConfirmation && (
          <Overlay>
            <ConfirmaModal className="confirmation-modal">
              <h2>¿Estás seguro de que deseas terminar la Mantencion Preventiva?</h2>
              <ConfirmaBtn className="confirmation-buttons">
                <Boton2 style={{ backgroundColor: '#43A854', }} /* onClick={aceptar} */ >Aceptar</Boton2>
                <Boton2 style={{ backgroundColor: '#E34747' }} /* onClick={cancelAceptado} */ >Cancelar</Boton2>s
              </ConfirmaBtn>
            </ConfirmaModal>
          </Overlay>
        )
      }
      <Alertas tipo={alerta.tipo}
        mensaje={alerta.mensaje}
        estadoAlerta={estadoAlerta}
        cambiarEstadoAlerta={cambiarEstadoAlerta}
      />

      <div>
        <BotonGuardar style={{ marginTop: '30px' }} onClick={volver} >Volver</BotonGuardar>
      </div>
    </ContenedorProveedor >
  )
}

export default EjecutarPresupuestoSt;