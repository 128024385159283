/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import Alertas from './Alertas';
import { auth, db } from '../firebase/firebaseConfig';
import { getDocs, collection, where, query  } from 'firebase/firestore';
import { ListarProveedor, Titulo  } from '../elementos/General';
import { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react'
import moment from 'moment';
import { FaRegFilePdf } from "react-icons/fa";

const ReporteMantoPrev = () => {
  //fecha hoy
  const fechaHoy = new Date();
  const user = auth.currentUser;
  const { users } = useContext(UserContext);

  const [alerta, cambiarAlerta] = useState({});
  const [estadoAlerta, cambiarEstadoAlerta] = useState(false);
  const [asignar, setAsignar] = useState([]);
  const [flag, setFlag] = useState(false);

  // Leer datos de cabecera Entradas
  const getIngresostcab = async () => {
    const traerCabecera = collection(db, 'ingresostcab');
    const dato = query(traerCabecera, where('emp_id', '==', users.emp_id), where('estado', '==', 'CERRADO'), where('tecnico', '==', users.correo));
    const data = await getDocs(dato)
    setAsignar(data.docs.map((doc, index) => ({ ...doc.data(), id: doc.id })))
  }
  const mantoprev = asignar.filter(mp => mp.servicio === 'MANTENCION PREVENTIVA');
  const asignarOrdmp = mantoprev.sort((a, b) => a.folio - b.folio);

  // Cambiar fecha
  const formatearFecha = (fecha) => {
    const dateObj = fecha.toDate();
    const formatear = moment(dateObj).format('DD/MM/YYYY HH:mm');
    // const fechaHoyF = moment(fechaHoy).format('DD/MM/YYYY HH:mm');
    return formatear;
  }

  useEffect(() => {
    getIngresostcab();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    getIngresostcab();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flag, setFlag])

  return (
    <div>
      {/* Lista de Mantenciones Preventivas */}
      <ListarProveedor>
        <Titulo>Mantención Preventiva</Titulo>
        <Table singleLine>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>N°</Table.HeaderCell>
              <Table.HeaderCell>N°Orden</Table.HeaderCell>
              <Table.HeaderCell>Rut</Table.HeaderCell>
              <Table.HeaderCell>Nombre</Table.HeaderCell>
              <Table.HeaderCell>Fecha de Ingreso</Table.HeaderCell>
              <Table.HeaderCell>Equipo</Table.HeaderCell>
              {/* <Table.HeaderCell>Modelo</Table.HeaderCell> */}
              <Table.HeaderCell>N°Serie</Table.HeaderCell>
              <Table.HeaderCell>Servicio</Table.HeaderCell>
              <Table.HeaderCell>PDF</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {asignarOrdmp.map((item, index) => {
              return (
                <Table.Row key={index}>
                  <Table.Cell >{index + 1}</Table.Cell>
                  <Table.Cell>{item.folio}</Table.Cell>
                  <Table.Cell>{item.rut}</Table.Cell>
                  <Table.Cell>{item.entidad}</Table.Cell>
                  <Table.Cell>{formatearFecha(item.date)}</Table.Cell>
                  <Table.Cell>{item.tipo}</Table.Cell>
                  {/* <Table.Cell>{item.modelo}</Table.Cell> */}
                  <Table.Cell>{item.serie}</Table.Cell>
                  <Table.Cell style={{ color: 'red' }}>{item.servicio}</Table.Cell>
                  <Table.Cell style={{ textAlign: 'center' }} title='Descargar Reporte Final'>
                    <Link disabled to={`/pdfmantpreventivo/${item.id}`} >
                      <FaRegFilePdf style={{ fontSize: '24px', color: 'red' }} />
                    </Link>
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      </ListarProveedor>

      <Alertas tipo={alerta.tipo}
        mensaje={alerta.mensaje}
        estadoAlerta={estadoAlerta}
        cambiarEstadoAlerta={cambiarEstadoAlerta}
      />
    </div>
  );
};

export default ReporteMantoPrev;