import React from 'react';

const AsignarRfid = () => {
    return (
        <div>
            <h1>Asignar RFID</h1>
            <h2>En proceso de Construcción</h2>
        </div>
    );
};

export default AsignarRfid;