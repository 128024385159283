import React from 'react'
import '../styles/section.css'

export const Section = () => {
    return (
        <>
            <div className='section'>
                {/* <img src='./construccion.png' alt='Construccion' /> */}
                <h2>Una Nueva forma de administrar sus dispositivos médicos! </h2>
            </div>
        </>
    )
}

export default Section;